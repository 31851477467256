<template>
  <div>
    <!-- pic4A4212ACEEC9AF83A3FC0A465A53FDB8.jpg -->
    <img class="rounded" src="@/assets/news-network/lego-club-battles-chaos.jpg">
    <p>
      The LEGO® Universe News Network has just received this transmission from Professor Brickkeeper! He's helping to solve a mystery found in this month's LEGO Club Magazine!
    </p>
    <p>
      <i>Greetings Club members! How I hope this minifig alphabet decoder reaches you safely! You can use it to discover the name of the dark mass of chaos energy that threatens my Universe!</i>
    </p>
    <p>
      <i>I fear we're outmatched by the dark menace! Soon it could consume the last shard of Pure Imagination and cover my world in chaos!</i>
    </p>
    <p>
      <i>You can help by getting into the
      <router-link :to="{ name: 'help-the-minifigs' }">Great Minifig Mission</router-link>!
      Send more minfigs to my Universe to join the battle to save imagination-- and fast!</i>
    </p>
    <center>
      <!-- picE00DD414D47C4B26C2211F7F09518057.gif -->
      <img class="rounded" src="@/assets/news-network/minifig-alphabet.gif" style="width: 220px; height: 410px; background: initial;">
    </center>
  </div>
</template>
